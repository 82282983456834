// courses
.courses
	+animateOpenPage

	&-block
		display: block

		position: relative

		text-decoration: none

		padding: 15px
		color: $black

		overflow: hidden

		margin-bottom: 15px

		background-color: #f6f6f6

		+borderRadius
		+animateOpenPage

		&:last-child
			margin-bottom: 0

		&-image
			width: 100%
			height: 175px

			border-radius: 100px

			margin-bottom: 15px

			+backgroundSetting
			+borderRadius

		&-text
			&__subtitle
				font-size: 18px
				margin-bottom: 5px

			&__title
				font-size: 26px
				margin-bottom: 5px

			&__description
				font-size: 18px
				margin-bottom: 15px

			&__auth
				font-size: 18px
				margin-bottom: 25px

				+bold

			&__price
				font-size: 24px
				margin-bottom: 15px

			&__btn
				width: 100%
