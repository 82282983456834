// timetable-page
.timetable-page
	margin: 15px 0

	+animateOpenPage

	&-wrapper
		padding: 10px
		border: 3px solid $mainColor

		+borderRadius

	&-cover
		&-image
			width: 100%
			height: 175px

			border-radius: 100px

			margin-bottom: 15px

			+backgroundSetting
			+borderRadius

		&-video
			width: 100%
			border-radius: 100px
			margin-bottom: 15px

			+borderRadius

		&-text
			&__subtitle
				font-size: 18px
				margin-bottom: 10px

			&__title
				font-size: 26px
				margin-bottom: 15px

			&__auth
				font-size: 18px
				margin-bottom: 10px

				+bold

			&__description
				font-size: 18px
				margin-bottom: 25px

			&__date
				font-size: 18px
				margin-bottom: 10px

				& > span
					font-size: 18px
					display: block
					font-weight: 400

	&-form
		margin-top: 35px

		&__title
			font-size: 21px
			margin-bottom: 15px

		&-input
			margin-bottom: 10px

		&__btn
			width: 100%
