// animations
@keyframes activeModalWrapper
	from
		opacity: 0
	to
		opacity: 1

@keyframes hiddenModalWrapper
	from
		opacity: 1
	to
		opacity: 0

@keyframes activeModalContent
	from
		transform: scale3d(.90,.90,.90)
	to
		transform: scale3d(1,1,1)

@keyframes hiddenModalContent
	from
		transform: scale3d(1,1,1)
	to
		transform: scale3d(.90,.90,.90)

@keyframes inputLabelTop
	0%
		margin-top: -15px
		opacity: 0

	100%
		margin-top: 15px
		opacity: 1

@keyframes shopPageTabs
	from
		right: -60%
	to
		right: 0

@keyframes openPage
	0%
		opacity: 0

	100%
		opacity: 1

@keyframes closePage
	0%
		opacity: 1

	100%
		opacity: 0

@keyframes ballPulse
	0%
		transform: scale(0)

	100%
		transform: scale(1.0)
		opacity: 0