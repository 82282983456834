// boxShadow
@mixin boxShadow
	box-shadow: 0 0 25px rgba(0, 0, 0, 0.05)

// animations
@mixin transition
	transition: all .4s ease

@mixin animateOpenPage
	animation: openPage .4s

@mixin activeModalWrapper
	animation: activeModalWrapper .2s

@mixin hiddenModalWrapper
	animation: hiddenModalWrapper .2s

@mixin activeModalContent
	animation: activeModalContent .2s

@mixin hiddenModalContent
	animation: hiddenModalContent .2s

@mixin borderRadius
	border-radius: 12px

@mixin backgroundSetting
	background-size: cover
	background-position: center center
	background-repeat: no-repeat