// footer
.footer
	margin: 15px 0 200px 0
	padding-bottom: 100px
	
	+animateOpenPage

	&-wrapper
		text-align: center

	&__info
		display: block
		font-size: 14px
		color: $gray
		margin-bottom: 10px

	&__link
		display: block
		font-size: 14px
		color: $gray
		margin-bottom: 10px
		text-decoration: underline
