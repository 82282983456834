// timetable
.timetable
	+animateOpenPage

	&-block
		display: block

		position: relative

		text-decoration: none

		padding: 15px
		color: $black

		overflow: hidden

		margin-bottom: 15px
		
		padding: 10px
		border: 3px solid $mainColor

		+borderRadius
		+animateOpenPage

		&:last-child
			margin-bottom: 0

		&-image
			width: 100%
			height: 175px

			border-radius: 100px

			margin-bottom: 15px

			+backgroundSetting
			+borderRadius

		&-text
			&__subtitle
				font-size: 18px
				margin-bottom: 5px

			&__title
				font-size: 26px
				margin-bottom: 15px

			&__auth
				font-size: 18px
				margin-bottom: 15px

				+bold

			&__description
				font-size: 18px
				margin-bottom: 25px

			&__date
				font-size: 18px
				margin-bottom: 10px

				& > span
					font-size: 14px
					display: block
					font-weight: 400

			&__btn
				width: 100%