// menu
.menu
    width: 100%
    max-width: calc( $maxWidthContainer - 25px )

    position: fixed
    bottom: 0
    left: 50%
    transform: translateX(-50%)

    padding: 10px 15px

    z-index: 1000

    display: flex
    justify-content: space-between
    align-items: center

    background-color: $white

    box-shadow: 0 0 30px rgba(0, 0, 0, 0.3)

    border-radius: 15px

    margin-bottom: 10px

    &-item
        display: flex
        flex-direction: column
        align-items: center

        font-size: 12px
        text-decoration: none
        color: $black

        +transition

        &.active
            color: $mainColor

            & svg
                fill: $mainColor

        & svg
            width: 25px
            height: 25px

            fill: $black

            margin-bottom: 5px
