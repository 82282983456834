// policy
.policy
	margin: 25px 0

	+animateOpenPage

	&-block
		margin-top: 25px
		
		+animateOpenPage

		&__text
			font-size: 16px
			margin-bottom: 10px

			& ul, ol
				margin-left: 15px

		&__image
			width: 100%
			border-radius: 4px
