@font-face {
	font-family: 'San Francisco Pro Light';
	src: url('.././fonts/SFProDisplay-Light.eot');
	src: url('.././fonts/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
	url('.././fonts/SFProDisplay-Light.woff') format('woff'),
	url('.././fonts/SFProDisplay-Light.ttf') format('truetype');

	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'San Francisco Pro Bold';
	src: url('.././fonts/SFProDisplay-Bold.eot');
	src: url('.././fonts/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
	url('.././fonts/SFProDisplay-Bold.woff') format('woff'),
	url('.././fonts/SFProDisplay-Bold.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

// mixin
@mixin regular {
	font-family: 'Manrope', sans-serif;
	font-weight: 400;
}
@mixin bold {
	font-family: 'Manrope', sans-serif;
	font-weight: 700;
}